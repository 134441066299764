var exports = {};

function isAbsolute(e) {
  return "/" === e.charAt(0);
}

function spliceOne(e, t) {
  for (var s = t, n = s + 1, i = e.length; n < i; s += 1, n += 1) e[s] = e[n];

  e.pop();
}

function resolvePathname(e, t) {
  void 0 === t && (t = "");
  var s,
      n = e && e.split("/") || [],
      i = t && t.split("/") || [],
      l = e && isAbsolute(e),
      r = t && isAbsolute(t),
      o = l || r;
  if (e && isAbsolute(e) ? i = n : n.length && (i.pop(), i = i.concat(n)), !i.length) return "/";

  if (i.length) {
    var u = i[i.length - 1];
    s = "." === u || ".." === u || "" === u;
  } else s = !1;

  for (var a = 0, c = i.length; 0 <= c; c--) {
    var f = i[c];
    "." === f ? spliceOne(i, c) : ".." === f ? (spliceOne(i, c), a++) : a && (spliceOne(i, c), a--);
  }

  if (!o) for (; a--; a) i.unshift("..");
  !o || "" === i[0] || i[0] && isAbsolute(i[0]) || i.unshift("");
  var h = i.join("/");
  return s && "/" !== h.substr(-1) && (h += "/"), h;
}

exports = resolvePathname;
export default exports;